// extracted by mini-css-extract-plugin
export var AngularIndusTechHireWeb = "AngularTechStacksMobile-module--AngularIndusTechHireWeb--0980e";
export var cardBg = "AngularTechStacksMobile-module--cardBg--25b0c";
export var cards = "AngularTechStacksMobile-module--cards--2f03d";
export var cardsContent = "AngularTechStacksMobile-module--cardsContent--81a48";
export var description = "AngularTechStacksMobile-module--description--627d1";
export var heading = "AngularTechStacksMobile-module--heading--41719";
export var iconssCard = "AngularTechStacksMobile-module--iconssCard--10eb5";
export var nav = "AngularTechStacksMobile-module--nav--9c05e";
export var navItem = "AngularTechStacksMobile-module--nav-item--2501b";
export var navbarBlock = "AngularTechStacksMobile-module--navbarBlock--e1b01";
export var tabData = "AngularTechStacksMobile-module--tabData--f5521";
export var tech = "AngularTechStacksMobile-module--tech--1945c";
export var techIcon = "AngularTechStacksMobile-module--techIcon--49674";
export var techImg = "AngularTechStacksMobile-module--techImg--c502e";
export var technologyIcon = "AngularTechStacksMobile-module--technologyIcon--64748";