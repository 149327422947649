import React, { useState } from "react"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Tab from "react-bootstrap/Tab"
import "./AngularTechStacks.scss"
import * as styles from "./AngularTechStacks.module.scss"

const data = [
  {
    title: "Frontend",
    tech: [
      {
        name: "React.js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/react_js_1154471199.svg",
      },
      {
        name: "Vue.js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/vue_js_0e0a8fb370.svg",
      },
      {
        name: "Next.js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/next_js_678c656bab.svg",
      },
      {
        name: "Nuxt.js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/nuxt_js_c2bde8049f.svg",
      },
      {
        name: "React Native",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/react_bbfc441a92.svg",
      },
    ],
  },
  {
    title: "Languages",
    tech: [
      {
        name: "HTML5",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/html5_bf38e2c0fb.svg",
      },
      {
        name: "CSS3",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/css_729ce9077e.svg",
      },
      {
        name: "JavaScript",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/javascript_51cf86eab4.svg",
      },
      {
        name: "Graph QL",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/graph_ql_9756ceaadc.svg",
      },
    ],
  },
  {
    title: "Database",
    tech: [
      {
        name: "PostgreSQL",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/postgresql_e2a729b197.svg",
      },
      {
        name: "MongoDB",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mongodb_b915357e37.svg",
      },
      {
        name: "Cassandra",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/cassandra_423865c8b5.svg",
      },
      {
        name: "ETS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ets_6e0f794a66.svg",
      },
      {
        name: "Mnesia",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mnesia_5d167e20b3.svg",
      },
      {
        name: "Redis",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/redis_832eeb3e2d.svg",
      },
      {
        name: "Couchbase",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/couchbase_7fa9cd943d.svg",
      },
    ],
  },
  {
    title: "Testing",
    tech: [
      {
        name: "Postman",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/postman_49dba5f42f.svg",
      },
      {
        name: "Jmeter",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/jmeter_4c76f78edc.svg",
      },
      {
        name: "Selenium",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/selenium_d2c2897f14.svg",
      },
      {
        name: "Cypress",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/cypress_610c0656c1.svg",
      },
      {
        name: "BrowserStack",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/browserstack_4bdae2829e.svg",
      },
      {
        name: "Bugzilla",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/bugzilla_080d09df97.svg",
      },
      {
        name: "SoapUI",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/soapui_7a84f8f709.svg",
      },
    ],
  },
  {
    title: "DevOps",
    tech: [
      {
        name: "Azure",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/azure_3871b2f717.svg",
      },
      {
        name: "Docker",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/docker_d3365e6dc2.svg",
      },
      {
        name: "Kubernetes",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/kubernetes_55c12db2f1.svg",
      },
      {
        name: "AWS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/aws_a3d0e0b081.svg",
      },
      {
        name: "Digital Ocean",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/digital_ocean_0cb308088c.svg",
      },
    ],
  },
]

const AngularTechStacks = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(data[0]?.title)

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <div className="WebTabStacksAngularHire">
      <div className={styles.TechStacksWebHireAngular}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={`${data[0]?.title}`}
          >
            <div className={styles.cardsContentAngular}>
              <div>
                <Nav variant="pills" className={`WebTabStacksAngularHire`}>
                  {data &&
                    data?.map((e, i) => (
                      <Nav.Item key={i} className="cards">
                        <div
                          eventKey={`${e?.title}`}
                          className={`tabLink ${
                            activeTab === e.title ? "active" : ""
                          }`}
                          onClick={() => handleTabClick(e.title)}
                        >
                          <div className={styles.tabData}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e?.title,
                              }}
                            />
                          </div>
                        </div>
                      </Nav.Item>
                    ))}
                </Nav>
              </div>
              <Tab.Content className="contentBlockAngularWeb">
                {data &&
                  data?.map((e, i) => (
                    <div
                      key={i}
                      className={`tab-pane ${
                        activeTab === e.title ? "active" : ""
                      }`}
                    >
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </Tab.Content>
            </div>
          </Tab.Container>
        </Container>
      </div>
    </div>
  )
}
export default AngularTechStacks
