// extracted by mini-css-extract-plugin
export var TechStacksWebHireAngular = "AngularTechStacks-module--TechStacksWebHireAngular--b58d9";
export var cardBg = "AngularTechStacks-module--cardBg--9da1e";
export var cards = "AngularTechStacks-module--cards--54374";
export var cardsContentAngular = "AngularTechStacks-module--cardsContentAngular--fc8f3";
export var description = "AngularTechStacks-module--description--957b4";
export var heading = "AngularTechStacks-module--heading--1cdc1";
export var iconssCard = "AngularTechStacks-module--iconssCard--2505f";
export var nav = "AngularTechStacks-module--nav--2423f";
export var navItem = "AngularTechStacks-module--nav-item--0929f";
export var navbarBlock = "AngularTechStacks-module--navbarBlock--e8c2e";
export var tabData = "AngularTechStacks-module--tabData--089e6";
export var tech = "AngularTechStacks-module--tech--cba7b";
export var techIcon = "AngularTechStacks-module--techIcon--1b4ce";
export var techImg = "AngularTechStacks-module--techImg--2a823";
export var technologyIcon = "AngularTechStacks-module--technologyIcon--0d60c";